.booksWrapper {
  display: flex;
  flex-wrap: wrap;
  column-gap: calc((100% - 800px) / 3);
  row-gap: 30px;
  margin: 50px 100px;
  align-items: flex-start;
  justify-content: flex-start;
}
.noBooks {
  margin: auto;
  width: 200px;
  margin-top: 50px;
  font-size: 16px;
  font-weight: bold;
  color: red;
}
