.cont {
  background-color: palegreen;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
}
.head {
  font-size: 24px;
}
.login {
  margin: 0;
  font-size: 16px;
  text-decoration: underline;
  color: blue;
  cursor: pointer;
}
