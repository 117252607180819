.cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.cont > span {
  font-size: 16px;
  color: black;
}
