.cont {
  display: flex;
  gap: 20px;
  margin: 50px auto;
  width: 800px;
}
.bookDetailsCont {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.bookDetails > span:first-child {
  font-weight: bold;
}
.bookButton {
  padding: 10px;
  font-weight: bold;
  font-size: 16px;
  width: 120px;
  margin-top: 20px;
  background: lightgrey;
  border-radius: 5px;
  cursor: pointer;
}
