.cont {
  margin-top: 20px;
}
.cont > p {
  font-size: 16px;
  font-weight: bold;
  font-style: italic;
}
.bookBriefCont {
  display: flex;
  gap: 10px;
  margin-top: 5px;
}
.bookImg {
  width: 300px;
  height: auto;
}
.bookBrief {
  font-size: 14px;
}
.bookBrief > div > span:first-child {
  font-weight: bold;
}
.bookDesc {
  margin-top: 5px;
}
