.cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 700px;
  margin: 20px auto;
}

.cont>p{
  font-size: 20px;
  font-weight: bold;
}